<template>
  <section class="products">
    <v-img
      v-if="info.banner"
      :src="info.banner"
      class="tnf-top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
        <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <v-tabs grow light v-model="category">
              <v-tab v-for="tab of categorys" :key="tab.key">
                <a :href="'/cases/'+tab.id">{{tab.name}}</a>
              </v-tab>
            </v-tabs>
        </v-flex>
      </v-layout>
    </v-img>
    <v-container grid-list-md text-center mb-2>
      <v-layout wrap>
        <v-flex
          xs12 sm6 md3 lg3 xl3
          mb-6
          v-for="(cs, i) in cases"
          :key="i"
        >
          <v-layout wrap ma-4 class="product-div">
            <v-flex xs12 sm12 md12 lg12 pa-0>
              <a :href="'/case/' + cs.id">
                <v-img
                  :src="cs.img"
                  aspect-ratio="1"
                  class="ctk-p-i"
                ></v-img>
              </a>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 pa-0>
              <div class="product-title">{{cs.title}}</div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    categorys: [],
    category: null,
    categoryInfo: null,
    caseIds: [],
    cases: null,
    catId: null,
    cats: null,
    pageId: 3,
    totalPage: 1,
    page: 1
  }),
  created () {
    this.catId = this.$route.params.pid
    this.getCategory(this.catId)
    this.getCases(this.page, this.catId)
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banner = val.banner[this.pageId][0].img
        let caseIds = []
        let categorys = []
        let cats = val.cases.category.cn
        for (let i in cats) {
          caseIds.push(cats[i].id)
          categorys.push(
            { key: i, id: cats[i].id, name: cats[i].name }
          )
          this.category = caseIds.indexOf(parseInt(this.catId))
        }
        this.caseIds = caseIds
        this.categorys = categorys
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banner = null
      if (this.$store.getters.companyInfo.banner) {
        banner = this.$store.getters.companyInfo.banner[this.pageId][0].img
      }
      return {
        banner: banner
      }
    }
  },
  methods: {
    getCategory (id) {
      this.https.get('case_category', { id: id }).then(response => {
        if (response.code === 0) {
          this.categoryInfo = response.data.cat
          if (this.categoryInfo.banner) {
            this.info.banner = this.categoryInfo.banner
          }
        }
      })
    },
    getCases (page, cid) {
      this.https.get('cases', { page: page, cid: cid, pageSize: 200 }).then(response => {
        if (response.code === 0) {
          this.cases = response.data.data
          this.totalPage = response.data.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/cases.css';
</style>
